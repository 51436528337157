const firebaseConfig = {
    apiKey: "AIzaSyBvhR62Kw47p9osgYxkI37ij6RB854MeS4",
    authDomain: "tourverse-ss.firebaseapp.com",
    projectId: "tourverse-ss",
    storageBucket: "tourverse-ss.appspot.com",
    messagingSenderId: "774411250868",
    appId: "1:774411250868:web:b88c618455f9af7bf0bbe1"
};

export default firebaseConfig;
